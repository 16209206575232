import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import tr from "../data/tr.json";
import eng from "../data/eng.json";
import { useLanguage } from "../context/LanguageContext";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      heroimage: file(relativePath: { eq: "test2.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 5120
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const { language } = useLanguage();
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col xl:px-32 items-center text-center gap-6 py-20">
          <h1
            className="font-display md:text-display-2xl text-display-lg"
            style={{
              color: "#65222D",
            }}
          >
            {language === "tr" ? tr.welcome : eng.welcome}
          </h1>
          <p className="col-span-8 md:text-body-xl text-body-lg font-light text-neutral-700 max-w-[800px]">
            {language === "tr" ? tr.exclusive : eng.exclusive}
          </p>
        </div>
      </div>
      <div className="relative">
        <GatsbyImage image={getImage(data.heroimage)} alt="Interior Design" />
      </div>
    </div>
  );
};

export default Hero;
