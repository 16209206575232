import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Metrics from "../components/metrics";
import Services from "../components/services";
import About from "../components/about";
import Works from "../components/works";
import RossoLaguna from "../components/rossolaguna";

const IndexPage = () => (
  <Layout>
    <Seo />
    <Hero />
    <About /> 
    <Metrics />  
    <RossoLaguna />
    <Services /> 
    <Works />
  </Layout>
);

export default IndexPage;
