import React from "react";

import ArchitectureIcon from "../images/service-icons/interior-design.svg";
import RenovationIcon from "../images/service-icons/building-renovation.svg";
import ConstructionIcon from "../images/service-icons/construction.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";
import en from "../data/eng.json";
import tr from "../data/tr.json";
import { useLanguage } from "../context/LanguageContext";
const Services = () => {
  const { language } = useLanguage();
  return (
    <div id="services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow
                label={
                  language === "tr" ? tr.services : en.services
                }
              />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                {language === "tr" ? tr.servicesSubText : en.servicesSubText}
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={ArchitectureIcon}
              title={language === "tr" ? tr.servicesTitle1 : en.servicesTitle1}
              description={
                language === "tr" ? tr.servicesText1 : en.servicesText1
              }
            />
            <ServiceItem
              icon={RenovationIcon}
              title={language === "tr" ? tr.servicesTitle2 : en.servicesTitle2}
              description={
                language === "tr" ? tr.servicesText2 : en.servicesText2
              }
            />
            <ServiceItem
              icon={ConstructionIcon}
              title={language === "tr" ? tr.servicesTitle3 : en.servicesTitle3}
              description={
                language === "tr" ? tr.servicesText3 : en.servicesText3
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
