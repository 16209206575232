import React from "react";
import { useLanguage } from "../context/LanguageContext";
import Eyebrow from "./eyebrow";
import tr from "../data/tr.json";
import en from "../data/eng.json";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
const RossoLaguna = () => {
  const { language } = useLanguage();
  const data = useStaticQuery(graphql`
    {
      bilgi1: file(relativePath: { eq: "bilgi1.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bilgi2: file(relativePath: { eq: "bilgi2.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bilgi3: file(relativePath: { eq: "bilgi3.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bilgi4: file(relativePath: { eq: "bilgi4.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      bilgi5: file(relativePath: { eq: "bilgi5.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <div id="rossolaguna">
      <div className="container mx-auto ">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12 ">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow
                label={
                  language === "tr"
                    ? tr.rossoLagunaHeadText
                    : en.rossoLagunaHeadText
                }
              />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                {language === "tr"
                  ? tr.rossoLagunaSubText
                  : en.rossoLagunaSubText}
              </h2>
            </div>
          </div>
          <div className="flex gap-8">
            <div
              className="flex justify-center flex-col md:flex-row"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ul
                className="md:text-body-lg text-body-md font-light text-neutral-700"
                style={{ maxWidth: "750px" }}
              >
                <li>
                  {" "}
                  {language === "tr"
                    ? tr.rossoLagunaDescription1
                    : en.rossoLagunaDescription1}
                </li>
                <li>
                  {" "}
                  {language === "tr"
                    ? tr.rossoLagunaDescription2
                    : en.rossoLagunaDescription2}
                </li>
                <li>
                  {" "}
                  {language === "tr"
                    ? tr.rossoLagunaDescription3
                    : en.rossoLagunaDescription3}
                </li>
              </ul>

              <div className="lg:col-span-6 flex flex-col gap-8 relative ml-0 md:ml-8">
                <GatsbyImage
                  image={getImage(data.bilgi1)}
                  alt="Rosso Laguna Texture"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse sm:flex-row gap-8">
            <div className="lg:col-span-6 flex flex-col gap-8 relative">
              <GatsbyImage
                image={getImage(data.bilgi2)}
                alt="Rosso Laguna Texture - 2"
              />
            </div>

            <ul
              className="md:text-body-lg text-body-md font-light text-neutral-700"
              style={{ maxWidth: "750px" }}
            >
              <li>
                {language === "tr"
                  ? tr.rossoLagunaDescription5
                  : en.rossoLagunaDescription5}
              </li>
              <li>
                {language === "tr"
                  ? tr.rossoLagunaDescription6
                  : en.rossoLagunaDescription6}
              </li>
              <li>
                {" "}
                {language === "tr"
                  ? tr.rossoLagunaDescription7
                  : en.rossoLagunaDescription7}
              </li>
            </ul>
          </div>

          <div className="flex gap-8">
            <div
              className="flex justify-center flex-col md:flex-row"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ul
                className="md:text-body-lg text-body-md font-light text-neutral-700"
                style={{ maxWidth: "750px" }}
              >
                <li>
                  {" "}
                  {language === "tr"
                    ? tr.rossoLagunaDescription9
                    : en.rossoLagunaDescription9}
                </li>
                <li>
                  {" "}
                  {language === "tr"
                    ? tr.rossoLagunaDescription10
                    : en.rossoLagunaDescription10}
                </li>
          
              </ul>

              <div className="lg:col-span-6 flex flex-col gap-8 relative ml-0 md:ml-8">
                <GatsbyImage
                  image={getImage(data.bilgi3)}
                  alt="Rosso Laguna Texture"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-8">
              <GatsbyImage
                image={getImage(data.bilgi4)}
                alt="Rosso Laguna Texture"
              />
              <GatsbyImage
                image={getImage(data.bilgi5)}
                alt="Rosso Laguna Texture"
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RossoLaguna;
