import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import tr from "../data/tr.json";
import en from "../data/eng.json";
import { useLanguage } from "../context/LanguageContext";
import Eyebrow from "./eyebrow";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "stone1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const { language } = useLanguage();

  return (
    <div id="about" name="about">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label={language === "tr" ? tr.about : en.about} />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
              Ozkan Marble
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            {language === "tr" ? tr.aboutText1 : en.aboutText1}
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            {language === "tr" ? tr.aboutText2 : en.aboutText2}
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
            {/* <img
              src={AwardBadge}
              alt="Award Badge"
              className="absolute left-[42%] -top-14"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
